<template>
  <div>
    <v-card flat tile>
      <v-tabs>
        <v-tab>
          {{ $t('settings.lang_addZvtEcTerminal') }}
        </v-tab>
        <v-tab>
          {{ $t('settings.lang_addAdyenEcTerminal') }}
        </v-tab>
        <v-tab>
          {{ $t('settings.lang_ccvOrPaxTerminal') }}
        </v-tab>
        <v-tab v-if="$store.getters['permissions/checkModule'](91)">
          {{ $t('settings.lang_cmiTerminal') }}
        </v-tab>
        <v-tab-item>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-select :items="kassenIDs" :label="this.$t('generic.lang_cashierID')" v-model="cashierID" outlined></v-select>
                  <v-text-field
                    v-model="terminalAliasName"
                    :label="$t('generic.lang_alias')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined 
                  />
                </v-col>

                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="zvtServerIP"
                                :label="'ZVT Server '+this.$t('generic.lang_ipAddress')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  />

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="zvtServerPort"
                                :label="$t('generic.lang_zvtServerPort')"
                                autocomplete="off"
                                required
                                type="number"
                                :rules="[rules.required]"
                  />
                </v-col>

                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="terminalIP"
                                :label="$t('generic.lang_ipAddressOfEcTerminal')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  />
                </v-col>

                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="terminalPort"
                                :label="$t('generic.lang_terminalPort')"
                                autocomplete="off"
                                type="number"
                                min="1"
                                :rules="[rules.required]"
                  />
                </v-col>

                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="terminalPW"
                                :label="$t('generic.lang_terminalPassword')"
                                type="password"
                                autocomplete="off"
                  />
                </v-col>

                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-checkbox v-model="registrationREQ" :label="this.$t('generic.lang_needsRegistration')"></v-checkbox>
                </v-col>

                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-checkbox v-model="printOnECR" :label="$t('settings.lang_ecZvtPrintOverCashierPrinter')"></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters justify-md="end" justify="center">
                <v-btn color="info" large :loading="loading" :disabled="!valid" @click="create()">
                  {{ this.$t('generic.lang_add') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-container>
            <v-card>
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align-self="center">
                  <v-avatar width="100%">
                    <img :src="adyenLogo"/>
                  </v-avatar>
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                  <v-card-text>
                    {{ $t('settings.lang_createAdyenHeaderText') }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-container>

          <v-form v-model="valid2" ref="form2">
            <v-container>
              <v-row>
                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-select :items="kassenIDs" :label="$t('generic.lang_cashierID')" v-model="cashierID" outlined>
                  </v-select>
                  <v-text-field
                      v-model="terminalAliasName"
                      :label="$t('generic.lang_alias')"
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      autocomplete="off"
                      outlined
                      required
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="terminalID"
                                :label="$t('settings.lang_adyenTerminalId')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  />
                </v-col>
                <v-col lg="4" md="12" sm="12" cols="12">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="adyenAPIKey"
                                :label="$t('settings.lang_adyenApiKey')"
                                :rules="[rules.required]"
                                autocomplete="off"
                  />
                </v-col>
                <v-col lg="4" md="6" sm="12" cols="12">
                  <v-checkbox v-model="printOnECR" :label="$t('settings.lang_ecZvtPrintOverCashierPrinter')"></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters justify-md="end" justify="center">
                <v-btn color="info" large :loading="loading" :disabled="!valid2" @click="createAdyen()">
                  {{ this.$t('generic.lang_add') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>

      <v-tab-item>
        <v-container>
          <v-card>
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4" align-self="center">
                <v-avatar width="170px">
                  <img :src="ccvLogo"/>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                <v-card-text>
                  {{ $t('settings.lang_ecPaymentConnectionForAndroidCcvOrPaxTerminals') }} 
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-container>

        <v-form v-model="valid3" ref="form3">
          <v-container>
            <v-row>
              <v-col lg="4" md="6" sm="12" cols="12">
                <v-select :items="kassenIDs" :label="$t('generic.lang_cashierID')" v-model="cashierID" outlined>
                </v-select>
                <v-text-field
                    v-model="terminalAliasName"
                    :label="$t('generic.lang_alias')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row no-gutters justify-md="end" justify="center">
              <v-btn color="info" large :loading="loading" :disabled="!valid3" @click="createCCV()">
                {{ this.$t('generic.lang_add') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>

      <v-tab-item v-if="$store.getters['permissions/checkModule'](91)">
        <v-container>
          <v-card class="d-flex">
            <img :src="cmiLogo" width="80" class="pa-2 mx-1"/>
                <v-card-text>
                  {{ $t('settings.lang_cmiIntegrationText') }} 
                </v-card-text>
          </v-card>
        </v-container>

        <v-form v-model="valid4" ref="form4">
          <v-container>
            <v-row>
              <v-col lg="4" md="6" sm="12" cols="12">
                <v-select :items="kassenIDs" :label="$t('generic.lang_cashierID')" v-model="cashierID" outlined>
                </v-select>
              </v-col>
              <v-col lg="4" md="6" sm="12" cols="12">
                <v-text-field
                    v-model="terminalAliasName"
                    :label="$t('generic.lang_alias')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="outlet_id"
                    :label="$t('generic.lang_outlet_id')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="terminal_id"
                    :label="$t('generic.lang_terminal_id')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="ecr_number"
                    :label="$t('generic.lang_ecr_number')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="cachier_id_CMI"
                    :label="$t('generic.lang_cmi_cachier_id')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
            </v-row>
            <v-row no-gutters justify-md="end" justify="center">
              <v-btn color="info" large :loading="loading" :disabled="!valid4" @click="createCMI()">
                {{ this.$t('generic.lang_add') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      </v-tabs>

    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import mixin from "../../../../mixins/KeyboardMixIns";
import {Events} from "../../../../plugins/events";
import {mapState} from "vuex";

export default {
  name: "CreateECTerminalComponent",
  mixins: [mixin],
  watch: {
    cashierID() {
      //console.log(this.cashierID)
    }
  },
  components: {},
  computed: {
    ...mapState([
      'cashierIDs',
    ]),
    ...mapState("api/auth", {
      "currentID": state => state.cashierID
    })
  },
  data() {
    return {
      rules: {
        required: (v) => {
          return !!v || this.$t('generic.lang_requiredField')
        }
      },
      loading: false,
      valid: false,
      valid2: false,
      valid3: false,
      valid4: false,
      adyenLogo: require("@/assets/images/ecterminal/adyen-logo-green.svg"),
      ccvLogo: require("@/assets/images/ecterminal/ccv.svg"),
      cmiLogo: require("@/assets/images/ecterminal/cmi-logo.png"),
      kassenIDs: [],
      currentCashierID: null,
      cashierID: null,
      terminalAliasName: null,
      terminalIP: null,
      terminalID: null,
      terminalPort: null,
      adyenAPIKey: null,
      terminalPW: null,
      zvtServerIP: null,
      zvtServerPort: null,
      registrationREQ: false,
      printOnECR: false,
      outlet_id: null,
      terminal_id: null,
      ecr_number: null,
      cachier_id_CMI: null,
    }
  },
  methods: {
    createCMI() {
      if (!this.$refs.form4.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post('create/settings/ecTerminal/', {
        cashierID: this.cashierID,
        terminalType: 4,
        terminalAliasName: this.terminalAliasName,
        outlet_id: this.outlet_id,
        terminal_id: this.terminal_id,
        ecr_number: this.ecr_number,
        cachier_id_CMI: this.cachier_id_CMI
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_terminalAdded'),
            color: "success"
          });
          this.$refs.form4.reset();

        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
        this.loading = false;

      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
        this.loading = false;
      })
    },
    createCCV() {
      if (!this.$refs.form3.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post('create/settings/ecTerminal/', {
        cashierID: this.cashierID,
        terminalType: 3,
        terminalAliasName: this.terminalAliasName,
        printOnECR: this.printOnECR,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_terminalAdded'),
            color: "success"
          });
          this.$refs.form3.reset();

        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
        this.loading = false;

      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
        this.loading = false;
      })
    },
    createAdyen() {
      if (!this.$refs.form2.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post('create/settings/ecTerminal/', {
        cashierID: this.cashierID,
        adyenTerminalID: this.terminalID,
        terminalAliasName: this.terminalAliasName,
        adyenAPIKey: this.adyenAPIKey,//paswword
        terminalType: 2,
        printOnECR: this.printOnECR,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_terminalAdded'),
            color: "success"
          });
          this.$refs.form2.reset();

        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
        this.loading = false;

      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
        this.loading = false;
      })
    },
    create() {

      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post('create/settings/ecTerminal/', {
        cashierID: this.cashierID,
        terminalAliasName: this.terminalAliasName,
        terminalIP: this.terminalIP,
        terminalPort: this.terminalPort,
        terminalPW: this.terminalPW,//paswword
        zvtServerIP: this.zvtServerIP+':'+this.zvtServerPort,
        terminalType: 1,
        registrationREQ: this.registrationREQ,
        printOnECR: this.printOnECR,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_terminalAdded'),
            color: "success",
          });
          this.$refs.form.reset();

        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error",
          });
        }
        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
        this.loading = false;
      })
    },

  },
  mounted() {
    this.kassenIDs = this.cashierIDs.availableCashierIDs;
    this.cashierID = this.currentID;
  }
}
</script>

<style scoped>

</style>
